<template>
  <div>
    <div class="flix-form-group">
      <assistentTitle :data="data" :callback="setTitle" :settings="settings" />
    </div>
    <div class="flix-form-group">
      <component v-if="title" :is="data.type" :data="data" :callback="saveData" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    eventpicker () { return import('@/components/assistent/components/eventpicker/timeRange') },
    datepicker () { return import('@/components/assistent/components/datepicker/timeRange') },
    assistentTitle () { return import('@/components/assistent/components/title') }
  },
  props: { data: Object, callback: Function, settings: Object },
  data () {
    return {
      title: this.data.title
    }
  },
  methods: {
    saveData (value) {
      var data = value
      data.title = this.title
      this.callback(data)
    },
    setTitle (value) {
      this.title = value.title
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
